<template>
	<div style="margin-top: 16px">
		<div class="d-flex" style="gap:16px">
			<div class="align-self-center">{{ label }}</div>
			<v-btn
					depressed
					color="primary white--text"
					min-width="32"
					width="32"
					@click="addItems"
			>
				<v-icon size="16" dark> iconfont icon-zengjia</v-icon>
			</v-btn>
			<v-btn
					depressed
					color="redness white--text"
					min-width="32"
					width="32"
					:disabled="selectSize === 0"
					@click="removeItems()"
			>
				<v-icon size="16" dark> iconfont icon-shanchu-fill</v-icon>
			</v-btn>
		</div>
		<div
				style="margin-bottom: 20px"
				v-for="(item, index) in list"
				:key="index">
			<div class="mb-3" style="flex-basis:100%;">
				<v-checkbox
						:label="`${label}${index+1}`"
						hide-details
						@change="setSelects($event, item)"
				></v-checkbox>
			</div>
			<div class="d-flex" v-if="showImage" style="width: 100%;">
				<material :show-add-icon="false" :value-image="item.imgUrl" @change="item.imgUrl = $event"/>
				<div style="width: 100%;">
					<v-text-field
							class="asterisk"
							label="字段名称"
							v-model="item[title]"
							required
							:rules="isEmptyRules"/>
					<v-text-field
							label="链接路径"
							v-model="item.path"
					/>
				</div>
			</div>
			<template v-else style="width: 100%;">
				<v-row>
					<v-col cols="6">
						<v-text-field
								class="asterisk"
								label="字段名称"
								v-model="item[title]"
								required
								:rules="isEmptyRules"/>
					</v-col>
					<v-col cols="6" v-if="!item.children || item.children.length === 0">
						<v-text-field
								class="asterisk"
								label="链接路径"
								v-model="item.path"
								required
								:rules="isEmptyRules"/>
					</v-col>
				</v-row>
				<div v-if="hasChildren">
					<div
							v-for="(child, i) in item.children"
							:key="i"
							class="d-flex align-center"
							style="column-gap: 24px;">
						<material :show-add-icon="false" :value-image="child.imgUrl" @change="child.imgUrl = $event"/>
						<div style="width: 100%;">
							<v-text-field
									class="asterisk"
									label="导航名称"
									v-model="child[title]"
									required
									:rules="isEmptyRules"/>
							<v-text-field
									class="asterisk"
									label="导航链接"
									v-model="child.path"
									required
									:rules="isEmptyRules"/>
						</div>
						<v-btn
								depressed
								color="redness white--text"
								min-width="32"
								width="32"
								@click="removeChildren(child, item)"
						>
							<v-icon size="16" dark> iconfont icon-shanchu-fill</v-icon>
						</v-btn>
					</div>
					<v-btn
							depressed
							color="primary white--text"
							@click="addChildren(item)"
					>
						<v-icon size="16"> iconfont icon-zengjia</v-icon>
						新增导航
					</v-btn>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import material from "@/components/common-material/material.vue";
import {uuid} from "vue-uuid";

export default {
	name: "input-config",
	props: {
		items: {
			type: Array,
			default: () => []
		},
		label: String,
		title: {
			type: String,
			default: 'title'
		},
		showImage: {
			type: Boolean,
			default: false
		},
		hasChildren: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isEmptyRules: [
				(v) => {
					if (v) {
						return true;
					}
					return "不得为空";
				},
			],
			selects: new Set(),
			selectSize: 0,
			list: []
		}
	},
	components: {
		material
	},
	methods: {
		setSelects(status, obj) {
			if (status) {
				this.selects.add(obj)
			} else {
				this.selects.delete(obj)
			}
			this.selectSize = this.selects.size
		},
		addItems() {
			let params = {}
			params[this.title] = ''
			params['path'] = ''
			this.list.push(params)
		},
		addChildren(item) {
			let params = {}
			params[this.title] = ''
			params['path'] = ''
			params['id'] = uuid.v1()
			if (!Array.isArray(item.children)) {
				item.children = []
			}
			item.children.push(params)
			item.path = ''
			this.$forceUpdate()
		},
		removeItems() {
			let indexs = [];
			let items = this.list
			for (const item of this.selects) {
				let index = items.indexOf(item);
				if (index > -1) {
					indexs.push(index)
				}
			}
			for (const i of indexs) {
				items.splice(i, 1)
			}
		},
		removeChildren(v, item) {
			let res = []
			item.children.forEach(c => {
				if (c.id !== v.id) {
					res.push(c)
				}
			})
			item.children = res
			this.$forceUpdate()
		}
	},
	watch: {
		items: {
			handler(v) {
				this.list = v
			},
			immediate: true,
			deep: true
		},
		list: {
			handler(v) {
				this.$emit('change', v)
			},
			immediate: true,
			deep: true
		}
	}
}
</script>

<style scoped>

</style>
