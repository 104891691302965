<template>
  <div style="padding: 20px; margin-bottom: 48px">
    <v-row>
      <v-col cols="6">
        <div class="c-one-title">顶部导航</div>
        <input-config
            label="一级栏目"
            :items="item.pageHeaders"
            @change="item.pageHeaders = $event"
            :has-children="true"/>
      </v-col>
      <v-col cols="6">
        <div class="c-one-title">登录及其他</div>
        <v-row>
          <v-col cols="4">
            <material
                title="登录页图片"
                sub-title="图片建议：宽高1920*480像素，大小不能超过2MB，格式仅限于jpg、png"
                :show-add-icon="false"
                :value-image="item.loginPicUrl"
                @change="item.loginPicUrl = $event"/>
          </v-col>
          <v-col cols="4">
            <material
                :required="true"
                title="网站LOGO"
                sub-title="图片建议：宽高100*60像素，大小不能超过2MB，格式仅限于png、svg"
                :show-add-icon="false"
                :accept="[
                    {range: 'image/png', suffixs: ['png']},
                    {range: 'image/svg+xml', suffixs: ['svg']}
                ]"
                :value-image="item.logoPicUrl"
                @change="item.logoPicUrl = $event"/>
          </v-col>
          <v-col cols="4">
            <material
                :required="true"
                title="浏览器页签ico"
                sub-title="图片建议：宽高16*16像素，大小不能超过2MB，格式仅限于ico"
                :show-add-icon="false"
                :accept="[{range: 'image/vnd.microsoft.icon', suffixs: ['ico']}]"
                :value-image="item.iconPicUrl"
                @change="item.iconPicUrl = $event"/>
          </v-col>
        </v-row>

        <v-text-field class="mt-6 col-md-6" label="登录页欢迎文本" v-model="item.loginText"/>
      </v-col>
    </v-row>

    <div
        style="
        background: #ffffff;
				bottom: 0;
				margin-left: -32px;
				position: fixed;
				width: 100%"
    >
      <div style="height: 1px; background: #e7e7e7; width: 100%;"></div>
      <div style="padding: 15px">
        <v-btn class="mr-4" depressed outlined color="primary" @click="reset">重置</v-btn>
        <v-btn depressed color="primary" @click="save">保存</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import material from "@/components/common-material/material.vue";
import inputConfig from "./input-config.vue";

export default {
  data() {
    return {
      item: {},
      o_item: {}
    }
  },
  components: {
    material,
    inputConfig
  },
  created() {
    this.axios.post('/api/menus/getbaseinfo', {tab: 0, type: "PC"}).then(res => {
      this.item = res.data.headerConfig
      this.o_item = JSON.parse(JSON.stringify(this.item))
    })
  },
  methods: {
    reset() {
      this.item = this.o_item
    },
    save() {
      this.axios.post('/api/menus/save_baseinfo', {headerConfig: this.item, type: 'PC'}).then(res => {
        this.snackbar.success('保存成功');
      })
    }
  }
}
</script>

<style scoped lang="scss">
.c-one-title {
  font-size: 18px;
  font-weight: bold;
  border-left: 2px solid var(--v-primary-base);
  padding-left: 8px;
  color: #333333;
}

.c-one-sub-title {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin: 8px 0;
}
</style>
